/** @jsx jsx **/
import { jsx } from 'theme-ui'
import { graphql } from 'gatsby'
import Layout from '~/components/layout'
import { ProductItemComponentType } from '~/@types/models'
import GiftCardDetail from '~/containers/product-detail/product-gift-card-detail'

interface Props {
  data: {
    product: ProductItemComponentType
  }
}

export const productGiftCardQuery = graphql`
  query ProductGiftCardQuery($slug: String) {
    product: shopifyProduct(handle: { eq: $slug }) {
      ...productDetail
      description
      moreFromCollection {
        ...productCollectionItem
      }
    }
  }
`

const GiftCardProductTemplate = ({ data: { product } }: Props) => {
  return (
    <Layout headerVariant="default">
      <GiftCardDetail product={product} />
    </Layout>
  )
}

export default GiftCardProductTemplate
