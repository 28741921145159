/** @jsx jsx */
import { jsx, Box, Flex, Text } from 'theme-ui'
import { FC, Fragment } from 'react'
import { space } from '~/gatsby-plugin-theme-ui'
import CompareAtPrice from '~/components/compare-at-price'
import useProductVariantInfo from '~/hooks/use-product-variant-info'
import { ProductItemComponentType, ProductVariantType } from '~/@types/models'

type Props = {
  product: ProductItemComponentType
  variant: ProductVariantType
  isFabric: boolean
}

const PriceBox: FC<Props> = ({ variant, product, isFabric }) => {
  const { isSale, priceLabel: price, compareAtPrice } = useProductVariantInfo({
    product,
    productVariant: variant,
    isFabric,
  })

  const showSalePrice = isSale && compareAtPrice
  return (
    <Box
      sx={{
        my: [space.md],
      }}
    >
      <Flex
        sx={{
          flexWrap: 'wrap',
          alignItems: 'flex-end',
        }}
      >
        {price && (
          <Box
            as={Text}
            mr={[24]}
            sx={{
              color: 'text',
              fontSize: [18],
              lineHeight: [1.5],
            }}
          >
            {(showSalePrice || !!compareAtPrice) && (
              <Fragment>
                SALE -{' '}
                <CompareAtPrice
                  price={compareAtPrice}
                  sxProps={{
                    fontSize: ['inherit', null, 'inherit'],
                    color: 'muted',
                    mr: space.xxs,
                  }}
                />
              </Fragment>
            )}
            {price}
          </Box>
        )}
      </Flex>
    </Box>
  )
}

export default PriceBox
